import { AmbienteDataInterface } from '@data-c/hooks'
import { MioAuth } from '@data-c/ui'
import { Box, LinearProgress } from '@mui/material'
import { useUserContext } from 'components/Contexts/UserContext'
import credentials from 'configs/credentials'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export default function Form() {
    const { loadUser } = useUserContext()
    const [obtendoUsuarioLogado, setObtendoUsuarioLogado] = useState(false)
    const navigate = useNavigate()

    async function handleLoginSucces(_: AmbienteDataInterface) {
        try {
            setObtendoUsuarioLogado(true)
            await loadUser()
            navigate('/usuarios')
        } catch (err) {
            //   notification.notifyException(err)
        } finally {
            setObtendoUsuarioLogado(false)
        }
    }

    return (
        <Box
            sx={{
                height: '100vh',
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {obtendoUsuarioLogado && (
                <Box>
                    <LinearProgress />
                    <div>Carregando informações da conta</div>
                </Box>
            )}
            {!obtendoUsuarioLogado && (
                <MioAuth
                    appName="Central de Contas"
                    credentialsConfig={credentials}
                    onLoginSuccess={handleLoginSucces}
                    onAlreadyLogged={() => {
                        // navigate('/usuarios')
                    }}
                    onLoginFailed={() => { }}
                />
            )}
        </Box>
    )
}
