import { FormProvider } from '@data-c/providers'
import { ButtonContainer } from '@data-c/ui'
import { Stack } from '@mui/material'
import { ContratoModel } from 'hooks/queries/useContratos'
import Form from './components/Form'
import Content from 'components/App/Content'
import Table from './components/Table'
import Delete from 'components/Delete'
import useApiTokens from 'hooks/queries/useApiTokens'
import Assistant from 'components/MioCandidate/Assistant'
interface TabTokenProps {
  contrato?: ContratoModel
}

export default function TabToken({ contrato }: TabTokenProps) {
  const { useDelete } = useApiTokens()
  const { mutateAsync } = useDelete()
  return (
    <Stack spacing={2}>
      <Assistant.Root>
        <Assistant.Article open={true} articleId="33580505781524" />
      </Assistant.Root>
      <FormProvider initialFormValues={{ contratoUuid: contrato?.uuid }}>
        <ButtonContainer>
          <Form />
        </ButtonContainer>

        <Content>
          <Table contratoUuid={contrato?.uuid || ''} />
        </Content>
        <Delete onDelete={mutateAsync} />
      </FormProvider>
    </Stack>
  )
}
