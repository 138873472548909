import { useState } from 'react'
import { Article } from '.'
import { Collapse, Stack, Typography } from '@mui/material'

interface VersaoItemProps {
  article: Article
  isOpen?: boolean
}

export default function VersaoItem(props: VersaoItemProps) {
  const { article, isOpen: _isOpen } = props
  const [isOpen, setOpen] = useState<boolean>(Boolean(_isOpen))

  return (
    <>
      <Stack
        onClick={() => setOpen(!isOpen)}
        sx={{
          p: 0.5,
          ':hover': {
            borderRadius: '4px',
            backgroundColor: (theme) => theme.palette.grey[200],
            cursor: 'pointer',
          },
        }}
      >
        <Typography variant="subtitle1">{article.title}</Typography>
      </Stack>
      <Collapse in={isOpen} sx={{ pl: 1, pr: 1 }}>
        <div
          dangerouslySetInnerHTML={{
            __html:
              article?.body
                ?.replace('width="560"', 'width="412px"')
                .replace('height="315"', 'height="240px"')
                .replace('h2', 'h3') || '',
          }}
        />
      </Collapse>
    </>
  )
}
