import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import useCrud, { EntityInterface } from 'hooks/useCrud'
import api from 'services/api'

export interface EmpresasModel extends EntityInterface {
  tipo: 'PESSOA_FISICA' | 'PESSOA_JURIDICA'
  documento: string
  razaoSocial?: string
  nome?: string
  ativo?: boolean
  protegida?: boolean
  configs?: object
}

export function useQueryObterUsuarios(empresaUuid?: string) {
  async function obterUsuarios(empresaUuid: string) {
    const response = await api.get(`v2/empresas/${empresaUuid}/users`)
    return response.data
  }

  return useQuery<any, AxiosError>(['empresa-usuarios', empresaUuid], () => {
    if (!empresaUuid) return null
    return obterUsuarios(empresaUuid)
  })
}
export default function useEmpresas() {
  return useCrud<EmpresasModel, AxiosError>(
    '/v2/empresas',
    'Empresas',
    'female',
  )
}
