import {
  useEffect,
  useRef,
  // useState
} from 'react'
import { Button, ButtonContainer } from '@data-c/ui'
import { Editor } from '@monaco-editor/react'
import { Stack } from '@mui/material'
import * as monaco from 'monaco-editor'
import useContratos, { ContratoModel } from 'hooks/queries/useContratos'

interface TabEditorProps {
  contrato?: ContratoModel
}

export default function TabEditor(props: TabEditorProps) {
  const { contrato } = props
  const { planoContratado } = contrato || {}
  const { useSubmit } = useContratos()
  const { mutateAsync, isLoading } = useSubmit()
  const editorRef = useRef<monaco.editor.IStandaloneCodeEditor | null>(null)

  const handleEditorChange = (_alue: any) => {}

  const handleEditorDidMount = (
    editor: monaco.editor.IStandaloneCodeEditor,
  ) => {
    editorRef.current = editor
    editor.getAction('editor.action.formatDocument')?.run()
    editor.setValue(JSON.stringify(planoContratado || {}))
  }

  const formatDocument = () => {
    if (editorRef.current) {
      editorRef.current.getAction('editor.action.formatDocument')?.run()
    }
  }

  useEffect(() => {
    // setJson(JSON.stringify(planoContratado || {}))
    formatDocument()
  }, [planoContratado])

  function handleSalvar() {
    if (editorRef.current && contrato) {
      const newValue = editorRef.current.getValue()
      mutateAsync({ ...contrato, planoContratado: newValue })
    }
  }

  return (
    <Stack spacing={2}>
      <ButtonContainer>
        <Button onClick={formatDocument}>Formatar</Button>
        <Button
          isLoading={isLoading}
          variant="contained"
          onClick={handleSalvar}
        >
          Salvar
        </Button>
      </ButtonContainer>
      <Editor
        height="82vh"
        defaultLanguage="json"
        // defaultValue={planoContratado}
        onChange={handleEditorChange}
        onMount={handleEditorDidMount}
        theme="vs-dark"
        options={{
          minimap: { enabled: false },
          fontSize: 14,
          automaticLayout: true,
          formatOnType: true, // Formata automaticamente enquanto você digita
          formatOnPaste: true, // Formata automaticamente quando você cola código
          tabSize: 2, // Define o tamanho da tabulação
          insertSpaces: true, // Insere espaços em vez de tabs
        }}
      />
    </Stack>
  )
}
