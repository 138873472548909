import { Checkbox, FormControlLabel, Grid2 as Grid } from '@mui/material'
import { FormContainer } from '@data-c/ui'
import { useFormApi, useFormData } from '@data-c/providers'
import { useValidations } from '@data-c/hooks'

import useAplicativos, { AplicativosModel } from 'hooks/queries/useAplicativos'

import * as yup from 'yup'
import { useUserContext } from 'components/Contexts/UserContext'
import { useEffect } from 'react'
import TextField from 'components/Inputs/TextField'

const schema = yup.object().shape({
  nome: yup.string().required('Informe o nome'),
  identificador: yup.string().required('Informe o identificador'),
})

export interface FormProps {
  data?: AplicativosModel
  view?: 'plain' | 'dialog'
}

export default function Form({ data: aplicativo, view }: FormProps) {
  const { onChangeFormValue, toggleSubmit, closeForm, changeValues } =
    useFormApi<AplicativosModel>()
  const { temPermissao } = useUserContext()
  const { formValues: data } = useFormData<AplicativosModel>()
  const { setValidationErrors, validationProps } = useValidations()
  const { useSubmit } = useAplicativos()
  const { mutateAsync } = useSubmit()

  useEffect(() => {
    if (aplicativo) {
      changeValues(aplicativo)
    }
  }, [aplicativo])

  function handleSubmitForm() {
    setValidationErrors(null)
    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        toggleSubmit(true)
        await mutateAsync(data)
        closeForm()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => toggleSubmit(false))
  }

  return (
    <FormContainer
      dialogProps={{ title: 'Cadastro de Aplicativo' }}
      onSubmitForm={handleSubmitForm}
      view={view}
    >
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
          <TextField
            label="Identificador"
            name="identificador"
            value={data?.identificador || ''}
            onChange={onChangeFormValue}
            {...validationProps('identificador')}
            disabled={Boolean(data?.uuid)}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
          <TextField
            label="Nome do Aplicativo"
            name="nome"
            value={data?.nome || ''}
            onChange={onChangeFormValue}
            {...validationProps('nome')}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
          <TextField
            label="URL do Aplicativo"
            name="url"
            value={data?.url || ''}
            onChange={onChangeFormValue}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
          <TextField
            multiline
            minRows={3}
            label="Descrição"
            name="descricao"
            value={data?.descricao || ''}
            onChange={onChangeFormValue}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
          <FormControlLabel
            label="Ativo"
            labelPlacement="end"
            control={
              <Checkbox
                name="ativo"
                checked={Boolean(data?.ativo)}
                onChange={onChangeFormValue}
              />
            }
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
          <FormControlLabel
            label="Usa Contrato"
            labelPlacement="end"
            control={
              <Checkbox
                name="usaContrato"
                checked={Boolean(data?.usaContrato)}
                onChange={onChangeFormValue}
              />
            }
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
          <FormControlLabel
            label="Usa empresas"
            labelPlacement="end"
            control={
              <Checkbox
                name="habilitaEmpresa"
                checked={Boolean(data?.habilitaEmpresa)}
                onChange={onChangeFormValue}
              />
            }
          />
        </Grid>
        {Boolean(temPermissao('aplicativos.protegidos')) && (
          <Grid size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
            <FormControlLabel
              label="Proteger"
              labelPlacement="end"
              control={
                <Checkbox
                  name="protegido"
                  checked={Boolean(data?.protegido)}
                  onChange={onChangeFormValue}
                />
              }
            />
          </Grid>
        )}
      </Grid>
    </FormContainer>
  )
}
