import {
  Avatar,
  Box,
  Button,
  Divider,
  MenuItem,
  Popover,
  Stack,
  Typography,
} from '@mui/material'
import credentialsConfig from 'configs/credentials'
import { Empresa, useCredentials, useDialog, usePopover } from '@data-c/hooks'
import { ArrowDropDown, Check, Logout } from '@mui/icons-material'
import { AlterarSenha, Surface } from '@data-c/ui'
import { useMemo } from 'react'
import { formatToCPFOrCNPJ } from 'brazilian-values'
import queryClient from 'services/query-client'

import Icon from 'components/MioCandidate/Icon'
import useNotification from 'hooks/useNotification'

export default function Profile() {
  const { logout, userLogged, selecionarLicenca, token } =
    useCredentials(credentialsConfig)
  const notification = useNotification()

  const {
    isOpen: isOpenAlterarSenha,
    openDialog: openAlterarSenha,
    closeDialog: closeAlterarSenha,
  } = useDialog()

  const {
    open: openMenu,
    close: closeMenu,
    isOpen: isOpenMenu,
    anchorEl,
  } = usePopover()

  function handleOpen(event: any) {
    openMenu(event)
  }

  function handleLogout() {
    logout()
    window.location.href = '/'
    // dispatch({ type: 'USER_LOGOUT' })
  }

  const empresas = useMemo(() => {
    if (userLogged) {
      return userLogged.permissoes['app-fdv']?.empresas || []
    }
    return []
  }, [userLogged])

  async function handleAlterarLicenca(e: Empresa) {
    try {
      await selecionarLicenca(token || undefined, e.uuid)
      queryClient.clear()
      queryClient.invalidateQueries()
      window.location.reload()
    } catch (err) {}
  }

  function handleOpenAlterarSenha() {
    openAlterarSenha()
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', alginItems: 'center' }}>
        <Button
          variant="text"
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
          onClick={handleOpen}
        >
          {userLogged ? `${userLogged.name}` : 'Usuário do Sistema'}
          <ArrowDropDown />
        </Button>
      </Box>

      <Popover
        open={isOpenMenu}
        onClose={closeMenu}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        sx={{
          '& .MuiPopover-paper': {
            // background: theme.palette.grey[100],
          },
        }}
      >
        <Stack
          sx={{ m: 2, minWidth: '300px' }}
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Avatar
            alt="photoURL"
            sx={{
              width: '64px',
              height: '64px',
              border: (theme) => `solid 2px ${theme.palette.grey[100]}`,
            }}
          />
          <Stack justifyContent="center" alignItems="center" spacing={0}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              Olá, {userLogged ? `${userLogged.name}` : 'Usuário do Sistema'}!
            </Typography>
            <Typography variant="body2">
              {userLogged ? userLogged.email : '---'}
            </Typography>
          </Stack>
        </Stack>

        {empresas.length > 0 && (
          <>
            <Divider />
            <Surface
              sx={{ m: 1, maxHeight: '300px', overflow: 'auto' }}
              elevation={0}
            >
              {empresas.map((e) => {
                return (
                  <MenuItem onClick={() => handleAlterarLicenca(e)}>
                    <Stack direction="row" gap={1} alignItems="center">
                      {userLogged?.empresaSelecionada === e.uuid && <Check />}
                      <Stack>
                        <Typography variant="body2">
                          {formatToCPFOrCNPJ(e.documento)}
                        </Typography>
                        <Typography variant="body2" color="#000">
                          {e.nome}
                        </Typography>
                      </Stack>
                    </Stack>
                  </MenuItem>
                )
              })}
            </Surface>
          </>
        )}

        <Divider />

        {/* <MenuItem
          onClick={(e) => {
            e.preventDefault()
            window.open(
              'https://datacsistemashelp.zendesk.com/hc/pt-br/categories/360001609791-For%C3%A7a-de-Vendas',
              '_blank',
              'noreferrer',
            )
          }}
          sx={{ m: 1 }}
        >
          <Stack direction="row" gap={1}>
            <HelpOutline />
            Manual do Sistema
          </Stack>
        </MenuItem> */}

        <MenuItem onClick={handleOpenAlterarSenha} sx={{ m: 1 }}>
          <Stack direction="row" gap={1}>
            <Icon.Password />
            Alterar Senha
          </Stack>
        </MenuItem>

        <Divider />
        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          <Stack direction="row" gap={1}>
            <Logout />
            Sair
          </Stack>
        </MenuItem>
      </Popover>

      <AlterarSenha
        credentialsConfig={credentialsConfig}
        isOpen={isOpenAlterarSenha}
        onClose={closeAlterarSenha}
        onFailed={(err: any) => {
          notification.notifyException(err)
        }}
        onSuccess={() => {
          notification.notifySuccess('Sua senha foi alterada com sucesso')
          closeAlterarSenha()
        }}
      />
    </Box>
  )
}
