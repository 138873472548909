import { Alert, Container, Tab, Tabs } from '@mui/material'
import TabPanel from 'components/TabPanel'
import useEmpresas, { EmpresasModel } from 'hooks/queries/useEmpresas'
import useTabs from 'hooks/useTabs'
import { useParams } from 'react-router-dom'
// import TabCadstro from './components/TabCadastro'
import { FormProvider } from '@data-c/providers'
import Loading from 'components/Displays/Loading'
// import TabConfigs from './components/TabConfigs'

import ContentTitle from 'components/MioCandidate/ContentTitle'
import Breadcrumbs from 'components/MioCandidate/Breadcrumbs'
import Content from 'components/Layouts/Content'
import TabCadastro from './TabCadastro'
import TabUsuarios from './TabUsuarios'

export default function Empresa() {
  const { id } = useParams()
  const { handleChange, value } = useTabs()
  const { useQueryByUuid } = useEmpresas()
  const { data, isLoading, error } = useQueryByUuid<EmpresasModel>(id as string)

  if (isLoading)
    return (
      <Container>
        <Loading />
      </Container>
    )

  return (
    <Container>
      <ContentTitle.Root>
        <Breadcrumbs
          links={[
            {
              label: 'Empresas',
              link: '/empresas',
            },
            {
              label: `${data?.nome || ''}`,
              link: `/empresas/${data?.uuid}`,
            },
          ]}
        />
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Cadastro" value={0} />
          <Tab label="Usuários" value={1} />
        </Tabs>
      </ContentTitle.Root>

      {error && <Alert severity="error">{error.message}</Alert>}
      <Content>
        <TabPanel value={value} index={0}>
          <FormProvider>
            <TabCadastro data={data || ({} as EmpresasModel)} />
          </FormProvider>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TabUsuarios data={data || ({} as EmpresasModel)} />
        </TabPanel>
      </Content>
    </Container>
  )
}
