import { Grid2 as Grid } from '@mui/material'
import { FormContainer } from '@data-c/ui'
import { useFormApi, useFormData } from '@data-c/providers'
import { useValidations } from '@data-c/hooks'

import * as yup from 'yup'
import TextField from 'components/Inputs/TextField'
import useApiTokens, { ApiToken } from 'hooks/queries/useApiTokens'

const schema = yup.object().shape({
  nome: yup.string().required('Informe o nome'),
  //   dataExpiracao: yup.date().nullable(), // Campo opcional para a data de expiração
})

export interface TokenFormProps {
  //   contratoUuid: string
  view?: 'plain' | 'dialog'
}

export default function Form({ view }: TokenFormProps) {
  const { onChangeFormValue, toggleSubmit, closeForm } = useFormApi()
  const { formValues } = useFormData<ApiToken>()
  const { useSubmit } = useApiTokens()
  const { mutateAsync } = useSubmit()
  const { setValidationErrors, validationProps } = useValidations()

  function handleSubmitForm() {
    setValidationErrors(null)
    schema
      .validate(formValues, { abortEarly: false })
      .then(async () => {
        toggleSubmit(true)
        await mutateAsync(formValues)
        closeForm()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => toggleSubmit(false))
  }

  return (
    <FormContainer
      dialogProps={{ title: 'Cadastro de Token' }}
      onSubmitForm={handleSubmitForm}
      view={view}
    >
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
          <TextField
            label="Nome do Token"
            name="nome"
            value={formValues?.nome || ''}
            onChange={onChangeFormValue}
            {...validationProps('nome')}
          />
        </Grid>
      </Grid>
    </FormContainer>
  )
}
