import { Box } from '@mui/material'
import useUsuarios, { UsuariosModel } from 'hooks/queries/useUsuarios'
import Table from './components/Table'
import { ButtonContainer } from '@data-c/ui'
import { FormProvider } from '@data-c/providers'
import Form from './components/Form'
import Delete from 'components/Delete'
import Section from 'components/MioCandidate/Section'
import Assistant from 'components/MioCandidate/Assistant'
interface TabAplicativosProps {
  usuario?: UsuariosModel
}

export default function TabAplicativos(props: TabAplicativosProps) {
  const { usuario } = props
  const { useRemoveAllPermissions } = useUsuarios()
  const { mutateAsync } = useRemoveAllPermissions()

  return (
    <Box>
      <Assistant.Root>
        <Assistant.Article open={true} articleId="33464566471444" />
      </Assistant.Root>
      <FormProvider initialFormValues={{ usuarioUuid: usuario?.uuid }}>
        <ButtonContainer>
          <Form usuarioUuid={usuario?.uuid} />
        </ButtonContainer>
        <Section>
          <Table
            usuarioUuid={usuario?.uuid}
            permissoes={usuario?.permissoesAsArray}
          />
        </Section>
        <Delete onDelete={mutateAsync} />
      </FormProvider>
    </Box>
  )
}
