import { useState } from 'react'
import { PlainQueryProps } from '@data-c/hooks'
import { Transporter, TransporterProps } from '@data-c/ui'
import useContratos, { ContratoModel } from 'hooks/queries/useContratos'
import ContratoTable from 'pages/Contratos/components/Table'
import ContratoForm from 'pages/Contratos/components/Form'
import { formatToCPFOrCNPJ } from 'brazilian-values'

type ContratoTransporterProps = {
  aplicativoUuid?: string
} & Omit<
  TransporterProps<ContratoModel>,
  'table' | 'form' | 'onSearch' | 'renderValue' | 'options'
>

export default function ContratoTransporter(props: ContratoTransporterProps) {
  const { value, aplicativoUuid, ...rest } = props
  const [query, setQuery] = useState<string | null>()
  const { useQuery } = useContratos()

  const { data, isLoading } = useQuery<
    Partial<ContratoModel | PlainQueryProps>
  >({
    queryParams: {
      plainQuery: query,
      aplicativoUuid: aplicativoUuid || null,
      ativo: true,
    },
    pagination: {
      page: 1,
      pageSize: 100,
    },
  })

  function handleSearch(q: string | null) {
    setQuery(q)
  }

  return (
    <Transporter
      onSearch={handleSearch}
      options={data?.data || []}
      isLoading={isLoading}
      table={ContratoTable}
      form={ContratoForm}
      label="Contrato"
      renderValue={(value) => {
        return `${value.aplicativo?.nome} (${
          value.aplicativo?.identificador
        }) - ${value.usuario?.name} (${formatToCPFOrCNPJ(
          value.usuario?.documento || '',
        )}) - ${value.empresa?.nome} (${formatToCPFOrCNPJ(
          value.empresa?.documento || '',
        )})`
      }}
      value={value}
      {...rest}
    />
  )
}
