import { FormProvider } from '@data-c/providers'
import { Container } from '@mui/material'
import Form from './components/Form'
import Table from './components/Table'
import useAplicativos from 'hooks/queries/useAplicativos'
import Delete from 'components/Delete'
import { useState } from 'react'
import ContentTitle from 'components/MioCandidate/ContentTitle'
import Breadcrumbs from 'components/MioCandidate/Breadcrumbs'
import Content from 'components/Layouts/Content'
import MioFilter from 'components/MioCandidate/Filter'
import Assistant from 'components/MioCandidate/Assistant'

export default function Aplicativos() {
  const { useDelete } = useAplicativos()
  const { mutateAsync } = useDelete()
  const [query, setQuery] = useState<string>()
  return (
    <Container>
      <Assistant.Root>
        <Assistant.Article open={true} articleId="33463981451668" />
      </Assistant.Root>
      <FormProvider initialFormValues={{ ativo: true }}>
        <ContentTitle.Root>
          <Breadcrumbs
            links={[
              {
                label: 'Aplicativos',
                link: '/aplicativos',
              },
            ]}
          />

          <MioFilter.Root>
            <MioFilter.SearchTextField
              onChange={(query) => {
                console.log('Query', 'query')
                setQuery(query)
              }}
              fullWidth
              autoFocus
              placeholder="Pesquisar por aplicativos"
            />
          </MioFilter.Root>

          <Form />
        </ContentTitle.Root>
        <Content>
          <Table query={query} />
        </Content>
        <Delete onDelete={mutateAsync} />
      </FormProvider>
    </Container>
  )
}
