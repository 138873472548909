import { Alert, Container, Tab, Tabs } from '@mui/material'
import TabPanel from 'components/TabPanel'
import useUsuarios, { UsuariosModel } from 'hooks/queries/useUsuarios'
import useTabs from 'hooks/useTabs'
import Form from 'pages/Usuarios/components/Form'
import { useParams } from 'react-router-dom'
import TabAplicativos from './components/TabAplicativos'
import ContentTitle from 'components/MioCandidate/ContentTitle'
import Breadcrumbs from 'components/MioCandidate/Breadcrumbs'
import Content from 'components/Layouts/Content'
import Loading from 'components/Displays/Loading'
import { FormProvider } from '@data-c/providers'

export default function Usuario() {
  const { id } = useParams()
  const { useQueryByUuid } = useUsuarios()
  const { data, isLoading, error } = useQueryByUuid<UsuariosModel>(id || '')
  const { handleChange, value } = useTabs()

  if (isLoading)
    return (
      <Container>
        <Loading />
      </Container>
    )

  return (
    <Container>
      <ContentTitle.Root>
        <Breadcrumbs
          links={[
            {
              label: 'Usuários',
              link: '/usuarios',
            },
            {
              label: `${data?.name || ''}`,
              link: `/usuarios/${id}`,
            },
          ]}
        />
        <Tabs value={value} onChange={handleChange} aria-label="tabs produtos">
          <Tab label="Cadastro" value={0} />
          <Tab label="Aplicativos" value={1} />
        </Tabs>
      </ContentTitle.Root>

      {error && <Alert severity="error">{error.message}</Alert>}
      <Content>
        <TabPanel value={value} index={0}>
          <FormProvider>
            <Form data={data} view="plain" />
          </FormProvider>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TabAplicativos usuario={data} />
        </TabPanel>
      </Content>
    </Container>
  )
}
