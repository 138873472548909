import { Stack } from '@mui/material'
import { ReactNode, useEffect, useRef } from 'react'
import Icon from 'components/MioCandidate/Icon'
import { useFilterApi } from './FilterContext'

export default function FilterContainer({ children }: { children: ReactNode }) {
  const boxRef = useRef<HTMLDivElement>(null)
  const { setContainer } = useFilterApi()

  useEffect(() => {
    // Função para atualizar a largura do componente Box
    const updateBoxWidth = () => {
      if (boxRef.current) {
        setContainer(boxRef.current)
        // console.log('width', boxRef.current.offsetWidth)
      }
    }

    // Calcula a largura inicial do Box
    updateBoxWidth()

    // Adiciona um listener para atualizar a largura ao redimensionar a janela
    window.addEventListener('resize', updateBoxWidth)

    // Limpeza do listener ao desmontar o componente
    return () => {
      window.removeEventListener('resize', updateBoxWidth)
    }
  }, [])

  return (
    <Stack
      ref={boxRef}
      direction="row"
      alignContent="center"
      alignItems="center"
      spacing={1}
    >
      <Icon.Search sx={{ fontSize: '0.95rem' }} />
      <Stack
        width="100%"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        alignContent="center"
      >
        {children}
      </Stack>
    </Stack>
  )
}
