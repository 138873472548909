import { FormProvider } from '@data-c/providers'
import { Container } from '@mui/material'
import Form from './components/Form'
import Table from './components/Table'
import useContratos from 'hooks/queries/useContratos'
import Delete from 'components/Delete'
import { useState } from 'react'
import ContentTitle from 'components/MioCandidate/ContentTitle'
import Breadcrumbs from 'components/MioCandidate/Breadcrumbs'
import Content from 'components/Layouts/Content'
import MioFilter from 'components/MioCandidate/Filter'

export default function Contratos() {
  const { useDelete } = useContratos()
  const { mutateAsync } = useDelete()
  const [query, setQuery] = useState<string>()

  return (
    <Container>
      <FormProvider initialFormValues={{ ativo: true }}>
        <ContentTitle.Root>
          <Breadcrumbs
            links={[
              {
                label: 'Contratos',
                link: '/contratos',
              },
            ]}
          />

          <MioFilter.Root>
            <MioFilter.SearchTextField
              onChange={(query) => {
                setQuery(query)
              }}
              fullWidth
              autoFocus
              placeholder="Pesquisar por contratos"
            />
          </MioFilter.Root>

          <Form />
        </ContentTitle.Root>
        <Content>
          <Table query={query} />
        </Content>
        <Delete onDelete={mutateAsync} />
      </FormProvider>
    </Container>
  )
}
