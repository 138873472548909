import { findData, usePagination } from '@data-c/hooks'
import { useFormApi } from '@data-c/providers'
import {
  DataTable,
  OptionStyles,
  DataTableOptions as Options,
} from '@data-c/ui'
import useApiTokens, { ApiToken } from 'hooks/queries/useApiTokens'
import useNotification from 'hooks/useNotification'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { useCallback, useMemo } from 'react'
import _ from 'lodash'
import { IconButton, Stack } from '@mui/material'
import { ContentCopy } from '@mui/icons-material'

interface TableProps {
  contratoUuid: string
}

export default function Table(props: TableProps) {
  const { contratoUuid } = props
  const { extractAxiosError } = useNotification()
  const { openConfirm } = useFormApi()
  const { pagination, changePageSize, changePage } = usePagination()
  const { useQuery } = useApiTokens()
  const notification = useNotification()
  const { data, isLoading, isFetching, error } = useQuery({
    queryParams: {
      contratoUuid,
    },
    pagination,
  })

  async function handleCopy(text: string) {
    try {
      await navigator.clipboard.writeText(text)
      notification.notifySuccess(
        'A token foi copiada para a área de transferência',
      )
    } catch (err) {
      // setMessage('Falha ao copiar o texto!')
      // setOpen(true)
    }
  }

  const handleClickItem = useCallback(
    (event: 'edit' | 'delete' | 'transport', data: ApiToken) => {
      switch (event) {
        case 'delete':
          openConfirm(data)
          break
      }
    },
    [openConfirm],
  )

  const tokens = data?.data.map((t) => ({
    ...t,
    // truncatedToken: _.truncate(t.token, { length: 100 }),
  }))

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'token',
        label: 'Token',
        options: {
          customBodyRender: (value) => (
            <Stack direction="row" spacing={2} alignItems="center">
              <IconButton
                onClick={() => {
                  handleCopy(value)
                }}
              >
                <ContentCopy color="primary" />
              </IconButton>
              {_.truncate(value, { length: 100 })}
            </Stack>
          ),
        },
      },
      {
        name: 'uuid',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          setCellProps: OptionStyles,
          customBodyRender: (value) => {
            const _data = findData<ApiToken>(data?.data || [], value)
            return (
              <Options
                // displayTransporterButton={enableTransporter}
                // displayDeleteButton={!enableTransporter}
                // displayUpdateButton={!enableTransporter}
                value={_data}
                onClick={handleClickItem}
              />
            )
          },
        },
      },
    ],
    [tokens, handleClickItem],
  )
  return (
    <DataTable
      error={extractAxiosError(error)}
      columns={columns}
      data={tokens || []}
      pagination={data?.pagination}
      isLoading={isLoading}
      isFetching={isFetching}
      changePage={changePage}
      changePageSize={changePageSize}
      options={{
        rowsPerPageOptions: [15, 50, 100],
        rowsPerPage: 15,
      }}
    />
  )
}
