import { findData, useDialog } from '@data-c/hooks'
import { Button, ButtonContainer, DataTable, Dialog } from '@data-c/ui'
import { Chip, Stack } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { EmpresasModel, useQueryObterUsuarios } from 'hooks/queries/useEmpresas'
import {
  EmpresaConfigsFormDataProps,
  Permissao,
  RemoveOnePermissionFormProps,
  useRemoveOnePermission,
  UsuariosModel,
} from 'hooks/queries/useUsuarios'
import { MUIDataTableColumnDef } from 'mui-datatables'
import EmpresaConfigsForm from 'pages/Usuario/components/TabAplicativos/components/EmpresaConfigsForm'
import { useMemo } from 'react'
import Assistant from 'components/MioCandidate/Assistant'
export interface TabCadstroProps {
  data: EmpresasModel
}

export default function TabUsuarios(props: TabCadstroProps) {
  const { data: empresa } = props
  const { data } = useQueryObterUsuarios(empresa?.uuid)
  const usuarios = data?.map((u: any) => u) || []
  const { mutateAsync, isLoading } = useRemoveOnePermission()
  const queryClient = useQueryClient()
  const {
    isOpen,
    openDialog,
    closeDialog,
    data: permissaoParaExcluir,
  } = useDialog<RemoveOnePermissionFormProps>()
  const {
    isOpen: isOpenEmpresaConfigsForm,
    openDialog: openDialogEmpresaConfigsForm,
    closeDialog: closeDialogEmpresaConfigsForm,
    data: empresaConfigsData,
  } = useDialog<EmpresaConfigsFormDataProps>()

  function handleClickRevokeOne(
    permissao: Permissao,
    empresa: EmpresasModel,
    usuarioUuid: string,
  ) {
    if (usuarioUuid && empresa?.uuid) {
      openDialog({ usuarioUuid, empresaUuid: empresa.uuid, permissao })
    }
  }

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'documento',
        label: 'Documento',
        options: {
          setCellProps: () => ({
            style: {
              width: '125px',
            },
          }),
        },
      },
      {
        name: 'email',
        label: 'Email',
        options: {
          setCellProps: () => ({
            style: {
              width: '255px',
            },
          }),
        },
      },
      {
        name: 'name',
        label: 'Nome',
      },
      {
        name: 'uuid',
        label: 'Aplicativos',
        options: {
          setCellProps: () => ({
            style: {
              // width: '100px',
              // textAlign: 'center',
            },
          }),
          customBodyRender: (value) => {
            const usuario = findData<UsuariosModel>(data || [], value, 'uuid')
            console.log(usuario)
            return (
              <Stack
                direction="row"
                spacing={1}
                useFlexGap
                flexWrap="wrap"
                // sx={{ backgroundColor: '#f4f', width: '80%' }}
              >
                {Object.keys(usuario?.permissoes || {}).map((key: string) => {
                  const permissao = usuario?.permissoes[key]
                  if (!permissao) return null
                  const _empresa = permissao?.empresas?.find(
                    (e) => e.uuid === empresa.uuid,
                  )
                  return (
                    <Chip
                      key={key}
                      //   deleteIcon={<Delete />}
                      onClick={() => {
                        openDialogEmpresaConfigsForm({
                          empresa: _empresa as EmpresasModel,
                          permissao: permissao,
                          usuarioUuid: usuario?.uuid || '',
                        })
                      }}
                      onDelete={() => {
                        if (permissao) {
                          handleClickRevokeOne(
                            permissao,
                            empresa,
                            usuario?.uuid || '',
                          )
                        }
                      }}
                      size="small"
                      label={permissao.nome}
                    />
                  )
                })}
              </Stack>
            )
          },
        },
      },
      //   {
      //     name: 'key',
      //     label: 'Ações',
      //     options: {
      //       filter: true,
      //       sort: false,
      //       empty: true,
      //       setCellProps: OptionStyles,
      //       customBodyRender: (value) => {
      //         const _data = findData<Permissao>(permissoes || [], value, 'key')
      //         return (
      //           <Options
      //             displayUpdateButton={false}
      //             value={_data}
      //             onClick={handleClickItem}
      //           />
      //         )
      //       },
      //     },
      //   },
    ],
    [data],
  )

  return (
    <>
      <Assistant.Root>
        <Assistant.Article open={true} articleId="33464359672852" />
      </Assistant.Root>
      <DataTable columns={columns} data={usuarios || []} />
      <Dialog
        title="Excluir Registro Permanentemente?"
        type="error"
        open={isOpen}
        maxWidth="xs"
        onClose={closeDialog}
        actions={
          <ButtonContainer>
            <Button
              title="Cancelar alteração de senha"
              onClick={() => closeDialog()}
            >
              Cancelar
            </Button>
            <Button
              color="error"
              variant="contained"
              onClick={async () => {
                if (permissaoParaExcluir) {
                  await mutateAsync(permissaoParaExcluir)
                  queryClient.invalidateQueries(['empresa-usuarios'])
                  closeDialog()
                }
              }}
              isLoading={isLoading}
            >
              Excluir
            </Button>
          </ButtonContainer>
        }
      >
        Você não poderá recuperar este registro após excluí-lo!
      </Dialog>
      <EmpresaConfigsForm
        isOpen={isOpenEmpresaConfigsForm}
        onClose={closeDialogEmpresaConfigsForm}
        data={empresaConfigsData || ({} as EmpresaConfigsFormDataProps)}
      />
    </>
  )
}
