import {
  useMutation,
  useQuery as useTQuery,
  useQueryClient,
} from '@tanstack/react-query'

import { AxiosError } from 'axios'
import api from 'services/api'
import useNotification from './useNotification'
import {
  HttpRequestInterface,
  HttpResponseInterface,
  CrudResultInterface,
} from '@data-c/hooks'

export interface EntityInterface {
  uuid?: string
}

export interface RequestInterface<TParams>
  extends HttpRequestInterface<TParams> {
  route: string
}

export function buildActionText(
  operation: 'create' | 'update' | 'delete' | 'read',
  gender: 'male' | 'female',
): string {
  const artigo = gender === 'male' ? 'o' : 'a'

  let acao = ''
  switch (operation) {
    case 'create':
      acao = `incluíd${artigo}`
      break
    case 'update':
      acao = `alterad${artigo}`
      break
    case 'delete':
      acao = `excluíd${artigo}`
      break
  }

  return acao
}

export async function read<P, T>(
  request: RequestInterface<P>,
): Promise<HttpResponseInterface<T>> {
  const { pagination: _pagination, sort, route, queryParams } = request
  const response = await api.get(route, {
    params: queryParams,
    headers: {
      'DC-Page': _pagination.page,
      'DC-PageSize': _pagination.pageSize,
      'DC-SortName': sort?.column,
      'DC-SortDirection': sort?.direction,
    },
  })

  const { data, meta: pagination } = response.data
  return { data, pagination }
}

async function create(route: string, data: any): Promise<any> {
  const response = await api.post(route, data)
  return response.data
}

async function update(route: string, data: any): Promise<any> {
  const response = await api.put(`${route}/${data?.uuid}`, data)
  return response.data
}

async function save(
  route: string,
  data: any,
): Promise<CrudResultInterface<any>> {
  function registroExiste(data: { uuid?: string }): boolean {
    return Boolean(data?.uuid)
  }

  if (registroExiste(data)) {
    const result = await update(route, data)
    return { data: result, operation: 'update' }
  }
  const result = await create(route, data)
  return { data: result, operation: 'create' }
}

async function remove(
  route: string,
  data: any,
  key: string = 'uuid',
): Promise<void> {
  await api.delete(`${route}/${data[key]}`)
  return data
}

export function generateEntityKey(entityName: string) {
  return entityName.replace(/ /g, '').toUpperCase()
}

export default function useCrud<
  TModel extends EntityInterface,
  TError = unknown,
>(
  route: string,
  entity: string,
  gender: 'male' | 'female' = 'male',
  key: string = 'uuid',
) {
  const notifications = useNotification()
  const queryClient = useQueryClient()
  const entityKey = generateEntityKey(entity)

  async function readById<T>(uuid: string): Promise<T> {
    const response = await api.get<T>(`${route}/${uuid}`)
    return response.data
  }

  function useQueryByUuid<T>(uuid: string) {
    return useTQuery<T, TError>([entityKey, uuid], () => {
      return readById<T>(uuid)
    })
  }

  function useQuery<TParams>(request: HttpRequestInterface<TParams>) {
    return useTQuery<HttpResponseInterface<TModel>, TError>(
      [entityKey, request],
      () => {
        return read<TParams, TModel>({ ...request, route })
      },
    )
  }

  function useSubmit() {
    return useMutation<any, AxiosError, TModel>((data) => save(route, data), {
      onSuccess(result: CrudResultInterface) {
        const acao = buildActionText(result.operation, gender)
        notifications.notifySuccess(`${entity} ${acao} com sucesso`)
        queryClient.invalidateQueries([entityKey])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    })
  }

  function useDelete() {
    const notifications = useNotification()
    const queryClient = useQueryClient()

    return useMutation<any, AxiosError, TModel>(
      (data) => remove(route, data, key),
      {
        onSuccess: async () => {
          const acao = buildActionText('delete', gender)
          queryClient.invalidateQueries([entityKey])
          notifications.notifySuccess(`${entity} ${acao} com sucesso`)
        },
        onError: (err) => {
          notifications.notifyException(err)
        },
      },
    )
  }

  return {
    useQuery,
    useQueryByUuid,
    useSubmit,
    useDelete,
    readById,
  }
}
