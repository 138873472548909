import { Grid2 as Grid } from '@mui/material'
import { FormContainer } from '@data-c/ui'
import { useFormApi, useFormData } from '@data-c/providers'
import { AplicativosModel } from 'hooks/queries/useAplicativos'
import * as yup from 'yup'
import AplicativoTransporter from 'components/Transporters/AplicativoTransporter'
import { useEffect } from 'react'
import EmpresaTransporter from 'components/Transporters/EmpresasTransporter'
import { EmpresasModel } from 'hooks/queries/useEmpresas'
import useUsuarios, { AddPermissionFormProps } from 'hooks/queries/useUsuarios'
import useValidations from 'hooks/useValidation'
import ContratoTransporter from 'components/Transporters/ContratoTransporter'
import { ContratoModel } from 'hooks/queries/useContratos'

const schema = yup.object().shape({
  aplicativoUuid: yup.string().required('Informe o aplicativo'),
})

interface FormProps {
  usuarioUuid?: string
}

export default function Form(props: FormProps) {
  const { usuarioUuid } = props

  const { changeValues, changeValue, toggleSubmit, closeForm } =
    useFormApi<AddPermissionFormProps>()

  const { formValues: data } = useFormData<AddPermissionFormProps>()
  const { setValidationErrors, validationProps } = useValidations()
  const { useAddPermission } = useUsuarios()
  const { mutateAsync } = useAddPermission()

  useEffect(() => {
    if (usuarioUuid) {
      changeValue('usuarioUuid', usuarioUuid)
    }
  }, [usuarioUuid])

  function handleSubmitForm() {
    setValidationErrors(null)
    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        toggleSubmit(true)
        await mutateAsync(data)
        closeForm()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => toggleSubmit(false))
  }

  return (
    <FormContainer
      dialogProps={{ title: 'Aplicativo / Licença' }}
      onSubmitForm={handleSubmitForm}
    >
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
          <AplicativoTransporter
            name="aplicativoUuid"
            value={data?.aplicativo || null}
            onChange={(value: AplicativosModel | null) => {
              changeValues({
                ...data,
                aplicativoUuid: value?.uuid || null,
                aplicativo: value || null,
              })
            }}
            {...validationProps('aplicativoUuid')}
          />
        </Grid>

        {Boolean(data?.aplicativo?.usaContrato) && (
          <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
            <ContratoTransporter
              aplicativoUuid={data?.aplicativoUuid || undefined}
              value={data?.contrato || null}
              onChange={(value: ContratoModel | null) => {
                changeValues({
                  ...data,
                  contratoUuid: value?.uuid || null,
                  contrato: value || null,
                })
              }}
              // disabled={(data?.step || 0) != 0}
            />
          </Grid>
        )}

        {Boolean(data?.aplicativo?.habilitaEmpresa) &&
          !Boolean(data?.aplicativo?.usaContrato) && (
            <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
              <EmpresaTransporter
                value={data?.empresa || null}
                onChange={(value: EmpresasModel | null) => {
                  changeValues({
                    ...data,
                    empresaUuid: value?.uuid || null,
                    empresa: value || null,
                  })
                }}
                // disabled={(data?.step || 0) != 0}
              />
            </Grid>
          )}
      </Grid>
    </FormContainer>
  )
}
