import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import useCrud, { EntityInterface, generateEntityKey } from 'hooks/useCrud'
import api from 'services/api'

export interface ApiToken extends EntityInterface {
  nome: string
  token: string
}

export function useQueryObterTokens(contratoUuid?: string) {
  async function obterTokens(contratoUuid: string) {
    const response = await api.get(`v2/api-token/${contratoUuid}`)
    return response.data
  }
  const key = generateEntityKey('ApiToken')
  return useQuery<any, AxiosError>([key, contratoUuid], () => {
    if (!contratoUuid) return null
    return obterTokens(contratoUuid)
  })
}

export default function useApiTokens() {
  return useCrud<ApiToken, AxiosError>('/v2/api-token', 'ApiToken', 'female')
}
