import { Stack } from '@mui/material'
import Content from './Content'
import Form from './Form'

export default function Login() {
    return (
        <Stack
            direction="row"
            sx={[
                {
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: { xs: 'auto', md: '100vh' },
                },
                (theme) => ({
                    backgroundImage:
                        'radial-gradient(ellipse at 70% 51%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
                    backgroundSize: 'cover',
                    ...theme.applyStyles('dark', {
                        backgroundImage:
                            'radial-gradient(at 70% 51%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))',
                    }),
                }),
            ]}
        >
            <Content />
            <Form />
        </Stack>
    )
}
