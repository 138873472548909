import { Close } from '@mui/icons-material'
import { IconButton, Stack, Typography } from '@mui/material'
// import Icon from 'components/MioCandidate/Icon'
interface AssistantHeaderProps {
  onClose: () => void
}

export default function AssistantHeader({ onClose }: AssistantHeaderProps) {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack direction="row" alignItems="center" spacing={0.5}>
        {/* <Icon.Help sx={{ fontSize: '12pt' }} /> */}
        <Typography variant="h6" fontWeight="bolder">
          Manual do Sistema
        </Typography>
      </Stack>

      <IconButton onClick={onClose}>
        <Close />
      </IconButton>
    </Stack>
  )
}
