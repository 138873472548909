import { Box } from '@mui/material'

export default function Content() {
  return (
    <Box
      sx={{
        height: '100vh',
        flex: 1,
        // display: { xs: 'none', md: 'flex' },
        display: 'none',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      Content
    </Box>
  )
}
