import {
  FormControlLabel,
  Grid2 as Grid,
  Switch,
  TextField,
} from '@mui/material'
import { FormContainer } from '@data-c/ui'
import { useFormApi, useFormData } from '@data-c/providers'
import { useValidations } from '@data-c/hooks'

import useUsuarios, { UsuariosModel } from 'hooks/queries/useUsuarios'

import * as yup from 'yup'
import DocumentoTextField from 'components/Inputs/DocumentoTextField'
import FormProps from 'interfaces/FormProps'
import { useEffect } from 'react'
import RolesTransporter from 'components/Transporters/RolesTransporter'
import { RoleModel } from 'hooks/queries/useRole'
import { useUserContext } from 'components/Contexts/UserContext'

const schema = yup.object().shape({})

export default function Form(props: FormProps<UsuariosModel>) {
  const { data: usuario, view = 'dialog' } = props
  const { formValues: data } = useFormData<UsuariosModel>()
  const { onChangeFormValue, toggleSubmit, closeForm, changeValues } =
    useFormApi<UsuariosModel>()
  const { isDatoon, temPermissao } = useUserContext()
  useEffect(() => {
    if (usuario) {
      changeValues(usuario)
    }
  }, [usuario])

  const { setValidationErrors, validationProps } = useValidations()
  const { useSubmit } = useUsuarios()
  const { mutateAsync } = useSubmit()

  function handleSubmitForm() {
    setValidationErrors(null)
    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        toggleSubmit(true)
        await mutateAsync(data)
        closeForm()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => toggleSubmit(false))
  }

  return (
    <FormContainer
      dialogProps={{ title: 'Cadastro de Usuários' }}
      onSubmitForm={handleSubmitForm}
      view={view}
    >
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}>
          <DocumentoTextField
            name="documento"
            inputProps={{
              label: 'Documento',
              name: 'documento',
              ...validationProps('documento'),
            }}
            value={data?.documento || ''}
            onChange={onChangeFormValue}
            disabled={Boolean(data?.uuid)}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 8, lg: 8, xl: 8 }}>
          <TextField
            label="Nome"
            name="name"
            value={data?.name || ''}
            onChange={onChangeFormValue}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 8, lg: 8, xl: 8 }}>
          <TextField
            label="E-mail"
            name="email"
            value={data?.email || ''}
            onChange={onChangeFormValue}
            disabled={Boolean(data?.uuid)}
          />
        </Grid>

        <Grid size={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}>
          <RolesTransporter
            helperText={
              !isDatoon(data?.email) &&
              'Usuário não pertence ao domínio @datac.com.br'
            }
            disabled={
              !isDatoon(data?.email) || !temPermissao('permissoes.visibilidade')
            }
            label="Permissões"
            name="email"
            value={data?.role || null}
            onChange={(value: RoleModel | null) => {
              changeValues({
                ...data,
                roleUuid: value?.uuid || null,
                role: value || null,
              })
            }}
          />
        </Grid>

        <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
          <FormControlLabel
            label="Ativo"
            labelPlacement="end"
            control={
              <Switch
                name="isActive"
                checked={Boolean(data?.isActive)}
                onChange={onChangeFormValue}
              />
            }
          />
        </Grid>
      </Grid>
    </FormContainer>
  )
}
